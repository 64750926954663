import { graphql } from "gatsby"
import React from "react"
import Helmet from "react-helmet"
import config from "../../data/SiteConfig"
import Footer from "../components/Footer/Footer"
import PostHeader from "../components/PostHeader/PostHeader"
import PostListing from "../components/PostListing/PostListing"
import SectionHeading from "../components/SectionHeading/SectionHeading"
import SEO from "../components/SEO/SEO"
import Layout from "../components/Layout/Layout"

export default function TagTemplate(props) {
  const { pageContext: {tag} } = props
  const postEdges = props.data.allMarkdownRemark.edges
  return (
    <Layout>
      <Helmet title={`Posts tagged as "${tag}" | ${config.siteTitle}`} />
      <SEO />
      <PostHeader config={config} />
      <div className="container grid grid--outer" style={{  paddingTop: "var(--spacing--0)" }}>
        <SectionHeading
          title={tag}
          id="skip__target"
          pageHeading
        />
      </div>
      <PostListing postEdges={postEdges} />
      <Footer config={config} />
    </Layout>
  )
}


/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query TagPage($tag: String) {
    allMarkdownRemark(
      filter: { 
        frontmatter: { 
          draft: { ne: true }, 
          hidden: { ne: true }, 
          tags: { in: [$tag] } 
        } 
      }
      sort: { fields: frontmatter___date, order: DESC }
      limit: 1000
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            tags
            category
            type
            thumb {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED, 
                  width: 400, 
                  height: 400, 
                  quality: 80,
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
